<template>
	<v-card flat>
		<v-card-text>
			<v-row class="pr-3 pl-3" v-if="show('source')">
				<v-checkbox
					v-model="source"
					v-for="c in sources"
					:key="c.value"
					:value="c.value"
					:label="c.text"
					class="mr-2"
				></v-checkbox>
			</v-row>
			<v-row>
				<v-col cols="12" md="4">
					<v-select
						v-if="show('statuses')"
						label="Status"
						multiple
						chips
						clearable
						outlined
						color="primary"
						v-model="statuses"
						:items="status_items"
					>
					</v-select>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select
						v-if="show('appointment_status')"
						label="Appointment Status"
						chips
						outlined
						clearable
						color="primary"
						v-model="appointment_status"
						:items="appointment_status_items"
					>
					</v-select>
				</v-col>
				<v-col cols="12" sm="4">
					<mw-date-picker
						v-if="show('date_start')"
						v-model="date_start"
						format="DD/MM/YYYY"
						label="Date Start"
					></mw-date-picker>
					<mw-date-picker
						v-model="date_end"
						v-if="show('date_end')"
						format="DD/MM/YYYY"
						label="Date End"
					></mw-date-picker>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<mw-select-users
						v-if="show('user')"
						v-model="user"
						:multiple="false"
						label="Participants"
						role="participant"
					></mw-select-users>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6">
					<mw-select-users
						v-if="show('created_by')"
						v-model="created_by"
						label="Submitter"
						:multiple="false"
						role="client"
					></mw-select-users>
				</v-col>
				<v-col cols="12" md="6">
					<mw-select
						v-if="show('projects')"
						v-model="projects"
						storeModule="projects"
						label="Projects"
						multiple
						item-text="internal_name"
					></mw-select>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import MwDatePicker from "@c/ui/MwDatePicker";
import MwSelectUsers from "@c/ui/MwSelectUsers";
import MwSelect from "@c/ui/MwSelect";
export default {
	name: "IncentiveFilters",
	props: {
		value: { type: Object },
	},
	data: () => {
		return {
			source: [],
			sources: [
				{ text: "Incentive Only Projects", value: "incentive-submissions" },
				{ text: "Appointments", value: "appointments" },
			],
			appointment_status_items: [
				{ text: "Complete", value: "complete" },
				{ text: "Cancelled", value: "cancelled" },
				{ text: "Cancelled/Client", value: "cancelled-client" },
				{ text: "Hold", value: "hold" },
			],
			appointment_status: null,
			date_start: null, //
			date_end: null,
			created_by: [],
			user: null,
			projects: [],
			statuses: [],
			status_items: [
				{ text: "New", value: "new" },
				{ text: "Paid", value: "paid" },
				{ text: "Ready", value: "ready" },
				{ text: "Unpaid", value: "unpaid" },
				{ text: "Processed", value: "processed" },
				{ text: "Contacted", value: "contacted" },
				{ text: "Hold", value: "hold" },
			],
		};
	},
	components: {
		MwDatePicker,
		MwSelectUsers,
		MwSelect,
	},
	computed: {
		filters() {
			let filters = {
				source: this.source,
				projects: this.projects,
				appointment_status: this.appointment_status,
				user: this.user,
				date_start: this.date_start,
				date_end: this.date_end,
				statuses: this.statuses,
				created_by: this.created_by,
			};
			return filters;
		},
	},
	methods: {
		show(v) {
			let keys = Object.keys(this.value);
			return keys.includes(v);
		},
	},
	watch: {
		filters(filters) {
			let values = this.value;
			for( var key in values ){
				values[key] = filters[key]
			}
			this.$emit("input", values);
		},
	},
	created() {
		for (var key in this.value) {
			this[key] = this.value[key];
		}
	},
};
</script>
