<template>
    <send-email-button
            class="mb-5"
            :values="{ user: value, client: 'Department for Education' }"
            email="incentive_reminder"
        >
            <template v-slot:button="scope">
                <v-btn
                    small
                    @click="scope.click"
                    color="primary"
                >
                    Remind
                </v-btn>
            </template>
        </send-email-button>
</template>

<script>
import SendEmailButton from "@c/communication/SendEmailButton";
export default {
    name: "Reminder",
    props: {
        value: {type: String}
    },
    components: {
        SendEmailButton
    }
}
</script>