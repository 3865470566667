var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"flat":""}},[_c('navigation'),_c('v-card',{staticClass:"rounded-lg mb-4",attrs:{"flat":"","color":"lightest"}},[_c('v-expansion-panels',{staticClass:"transparent",attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"transparent"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.panel == 0 ? "Hide filters" : "Show filters")+" ")]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('filter-records',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Update")])],1)],1)],1),_c('v-expansion-panel',{staticClass:"transparent"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.panel == 1 ? "Hide column settings" : "Show column settings")+" ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mb-3 pa-5"},_vm._l((_vm.columns),function(column){return _c('v-checkbox',{key:column.value,staticClass:"mr-4",attrs:{"hide-details":"","label":column.text},model:{value:(column.display),callback:function ($$v) {_vm.$set(column, "display", $$v)},expression:"column.display"}})}),1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'contacted')?_c('remind',{attrs:{"value":item.user}}):_vm._e()]}},{key:"item.submitted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.toDate().toLocaleDateString())+" ")]}},{key:"item.payment_details",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('payment-details',{attrs:{"id":item.user}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"value":item.status,"items":_vm.statusItems},on:{"change":function (v) { return _vm.update(item, v); }}})]}},{key:"footer.prepend",fn:function(){return [_c('export',{attrs:{"rows":_vm.items,"columns":_vm.headers,"name":"Incentives","disabled":!_vm.items.length}})]},proxy:true}])}),_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }