<template>
	<v-sheet flat>
		<navigation></navigation>
		<v-card flat color="lightest" class="rounded-lg mb-4">
			<v-expansion-panels v-model="panel" flat class="transparent">
				<v-expansion-panel class="transparent">
					<v-expansion-panel-header>
						{{ panel == 0 ? "Hide filters" : "Show filters" }}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card-text>
							<filter-records v-model="filters"></filter-records>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="submit" color="primary">Update</v-btn>
						</v-card-actions>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel class="transparent">
					<v-expansion-panel-header>
						{{
							panel == 1
								? "Hide column settings"
								: "Show column settings"
						}}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row class="mb-3 pa-5">
							<v-checkbox
								hide-details
								v-for="column in columns"
								class="mr-4"
								:key="column.value"
								:label="column.text"
								v-model="column.display"
							></v-checkbox>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<v-data-table
			:search="search"
			:headers="headers"
			:items="items"
			:loading="loading"
		>
			<template v-slot:item.actions="{ item }">
				<remind
					v-if="item.status == 'contacted'"
					:value="item.user"
				></remind>
			</template>
			<template v-slot:item.submitted_at="{ item }">
				{{ item.created_at.toDate().toLocaleDateString() }}
			</template>
			<template v-slot:item.submitted_at="{ item }">
				{{ item.created_at.toDate().toLocaleDateString() }}
			</template>
			<template v-slot:item.payment_details="{ item }">
				<payment-details v-if="item.user" :id="item.user"></payment-details>
			</template>
			<template v-slot:item.status="{ item }">
				<v-select
					@change="(v) => update(item, v)"
					:value="item.status"
					:items="statusItems"
				></v-select>
			</template>
			<template v-slot:footer.prepend>
				<export
					:rows="items"
					:columns="headers"
					name="Incentives"
					:disabled="!items.length"
				></export>
			</template>
		</v-data-table>
		<v-text-field v-model="search" label="Search"></v-text-field>
	</v-sheet>
</template>

<script>
import FilterRecords from "@c/incentives/Filter.vue";
import Navigation from "@c/incentives/Navigation";
import Export from "@c/downloads/Export";
import Remind from "@c/incentives/Remind";
import PaymentDetails from "@c/incentives/PaymentDetails";
export default {
	name: "AllIncentiveRecords",
	data: () => {
		return {
			panel: 0,
			search: null,
			loading: true,
			filters: {
				source: "appointments",
				appointment_status: "complete",
				created_by: [],
				date_start: null,
				date_end: null,
				user: "",
				projects: [],
				statuses: [],
			},
			columns: [
				{ value: "name", text: "Name", display: true },
				{ value: "email", text: "Email", display: true },
				{
					value: "appointment_date",
					text: "Appointment Date",
					display: true,
				},
				{ value: "amount", text: "Amount", display: true },
				{ value: "sow", text: "Reference", display: false },
				{ value: "project", text: "Project", display: false },
				{ value: "payment_status", text: "Payment Status", display: false },
				{ value: "submitter", text: "Submitter", display: false },
				{ value: "submitted_at", text: "Submitted At", display: false },
				{ value: "status", text: "Status", display: true },
				{ value: "payment_details", text: "Payment Info", display: true },
				{ value: "actions", text: "Actions", display: true },
			],
			statusItems: [
				{ value: "unpaid", text: "Unpaid" },
				{ value: "paid", text: "Paid" },
				{ value: "hold", text: "Hold" },
				{ value: "ready", text: "Ready" },
				{ value: "contacted", text: "Contacted" },
				{ value: "problem", text: "Problem" },
			],
		};
	},
	computed: {
		
		appointments(){
			let filters = {
				payment_statuses: this.filters.statuses, 
				projects: this.filters.projects, 
				date_start: this.filters.date_start,
				date_end: this.filters.date_end,
			};
			if( this.filters.user ){
				filters.users = [this.filters.user]
			}
			if( this.filters.appointment_status ){
				filters.appointment_statuses = [this.filters.appointment_status]
			}
			return this.$store.getters["singleAppointments/getByFilters"](filters);
		},
		users() {
			return this.$store.state.users.data;
		},
		projects() {
			return this.$store.state.projects.data;
		},
		incentiveOnlyRecords() {
			if (this.filters.source == "incentive-submissions") {
				return this.$store.getters["incentiveSubmissions/getByFilters"](
					this.filters
				);
			}

			return [];
		},
		appointmentFilters() {
			let filters = this.filters;
			filters.payment_statuses = filters.statuses;
			return filters;
		},
		individualAppointments() {

			return [];
		},
		headers() {
			return this.columns.filter((c) => c.display);
		},
		items() {
			const self = this;
			let items = [];

			self.incentiveOnlyRecords.forEach((r) => {
				let item = { ...r };
				item.name = item.first_name + " " + item.last_name;
				item.submitter = self.users[item.created_by]
					? self.users[item.created_by].name
					: "";
				let project = self.projects[item.project];
				item.project = project ? project.internal_name : "";
				items.push(item);
			});
			self.appointments.forEach((r) => {
				let item = { ...r };
				let user = self.users[item.user];
				item.appointment_participant = item.id;
				item.name = user ? user.name : "";
				item.email = user ? user.email : "";
				let project = self.projects[item.project];
				item.project = project ? project.internal_name : "";
				item.amount = project ? project.incentive : "";
				item.status = item.payment_status;
				items.push(item);
			});

			return items;
		},
	},
	components: {
		FilterRecords,
		Export,
		PaymentDetails,
		Navigation,
		Remind,
	},
	methods: {

		update(item, value) {
			if (item.appointment_participant) {
				this.$store
					.dispatch("appointmentParticipants/patch", {
						id: item.appointment_participant,
						payment_status: value,
					})
					.then(() => {
						this.$root.$emit("showToast", "Updated");
					});
			} else {
				this.$store
					.dispatch("incentiveSubmissions/patch", {
						id: item.id,
						status: value,
					})
					.then(() => {
						this.$root.$emit("showToast", "Updated");
					});
			}
		},
		submit() {
			const self = this;
			if (self.filters.source == "appointments") {
				self.$store
					.dispatch(
						"singleAppointments/fetchByFilters",
						self.appointmentFilters
					)
					.then(() => {
						self.loading = false;
					});
			}
			if (this.filters.source == "incentive-submissions") {
				this.$store
					.dispatch("incentiveSubmissions/fetchByFilters", this.filters)
					.then(() => {
						self.loading = false;
					});
			}
		},
	},
	created() {
		this.$store.dispatch("incentives/start");
	},
};
</script>
